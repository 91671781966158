import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import AddIcon from '@mui/icons-material/Add';
import {
  fetchDocumentContent,
  createDocument,
  updateDocument,
  deleteDocument,
  analyzeOrder
} from '../../api/documents.js';
import { createOrderV2, togglePinDocumentV2, fetchOrdersV2, fetchOrderDetailsV2 } from '../../api/ordersV2.js'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LinkIcon from '@mui/icons-material/Link';
import PDFViewer from '../../components/PDFViewer.js';
import ClearIcon from '@mui/icons-material/Clear';


const docTypes = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Sales Order', label: 'Sales Order' },
  { value: 'Packing List', label: 'Packing List' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Receiving Instructions', label: 'Receiving Instructions' },
  { value: 'Receiving Order', label: 'Receiving Order' },
  { value: 'Certifications', label: 'Certifications' },
  { value: 'Certificate of Analysis', label: 'Certificate of Analysis' },
  { value: 'Bill of Lading', label: 'Bill of Lading' },
  { value: 'Upload', label: 'Upload' },
  { value: 'Other', label: 'Other' },
];

export default function DocumentsV2({
  rows,
  setRows,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading,
  inputIntegrationId = null,
  height = "86vh",
}) {
  const [blobUrl, setBlobUrl] = useState(null);
  const [blobType, setBlobType] = useState(null);
  const [linkOptions, setLinkOptions] = useState([]);
  const fileInputRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'application/zip',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDF, XLSX, PNG, and JPEG are allowed.');
      return;
    }

    files.forEach((file) => uploadDocument(file));
  };

  const uploadDocument = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        const response = await createDocument(
          inputIntegrationId || integrations.find((integration) => integration.integration_type.category === "HQ").id,
          file.name,
          b64encodedString
        );
        setRows(prevRows => [response, ...prevRows]);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage(`Error uploading ${file.name}. It may already exist.`);
      }
    };
  };

  const handlePasteClipboard = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.read) {
        const clipboardItems = await navigator.clipboard.read();

        for (const item of clipboardItems) {
          if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
            const blob = await item.getType(item.types[0]);
            const timestamp = (new Date()).toISOString().replace(/[:.]/g, '-');
            const file = new File([blob], `pasted-${item.types[0].split('/')[1]}-${timestamp}.${item.types[0].split('/')[1]}`, { type: blob.type });
            uploadDocument(file);
          }
        }
      } else {
        console.log("Clipboard access not supported or allowed.");
      }
    } catch (error) {
      console.error("Failed to read clipboard contents:", error);
    }
  };

  const handleFetchDocument = async (row) => {
    setBlobUrl(null);
    try {
      const data = await fetchDocumentContent(row.msg_id, row.document_name);
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream';
      if (row.document_name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.document_name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.document_name.endsWith('.jpg') || row.document_name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.document_name.endsWith('.zip')) {
        mimeType = 'application/zip';
      } else if (row.document_name.endsWith('.xlsx')) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.document_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDeleteDocument = async (id) => {
    try {
      await deleteDocument(id);
      setRows(rows.filter((r) => r.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  }

  const handleDocumentChange = async (event, row, changedVal) => {
    try {
      const updatedValue = event.target.value;
      let updatedRow = { ...row };
      if (changedVal === 'category') {
        updatedRow = { ...updatedRow, category: updatedValue };
      }
      if (changedVal === 'order_name') {
        updatedRow = { ...updatedRow, order_name: updatedValue };
      }
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_id: updatedValue };
      }
      if (changedVal === 'is_published') {
        updatedRow = { ...updatedRow, is_published: updatedValue };
      }
      const data = await updateDocument(updatedRow);
      updatedRow.id = data.id;
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_name: integrations.find((integration) => integration.id === updatedValue)?.name };
      }
      setRows(rows.map((r) => (r.id === row.id ? updatedRow : r)));
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleAnalyzeDocument = async (row) => {
    setIsProcessing(true);
    setRows(rows.map((r) => (r.id === row.id ? { ...r, is_processing: true } : r)));
    try {
      const data = await analyzeOrder(row.id);
      if (data.body.order_name) {
        const pdfWithIntegrationId = {
          ...data.body,
          integration_id: row.integration_id,
          document_id: row.id,
          is_sales_order: row.category === 'Sales Order' ? true : false,
        };
        const order_data = await createOrderV2(pdfWithIntegrationId);
        setRows(rows.map((r) => (r.id === row.id ? { ...r, order_header_id: order_data.id, is_processing: false } : r)));
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAnalyzeDocuments = async () => {
    setIsProcessing(true);

    try {
      const rowsToUpdate = rows.filter(
        (row) =>
          ['Sales Order', 'Purchase Order', 'Receiving Order'].includes(row.category) &&
          !row.order_header_id &&
          !row.document_name.endsWith('.zip') &&
          !row.document_name.endsWith('.xlsx')
      );

      if (rowsToUpdate.length === 0) {
        setSnackbarOpen(true);
        setSnackbarMessage("No documents are currently parsable.");
        setSnackbarAlert("info");
        setIsProcessing(false);
        return;
      }

      const updatedProcessingRows = rows.map((row) =>
        rowsToUpdate.find((updateRow) => updateRow.id === row.id)
          ? { ...row, is_processing: true }
          : row
      );
      setRows(updatedProcessingRows);

      const updatePromises = rowsToUpdate.map(async (row) => {
        try {
          const data = await analyzeOrder(row.id);
          const pdfWithIntegrationId = {
            ...data.body,
            integration_id: row.integration_id,
            document_id: row.id,
            is_sales_order: row.category === 'Sales Order' ? true : false,
          };
          const order_data = await createOrderV2(pdfWithIntegrationId);
          return { ...row, order_header_id: order_data.id, is_processing: false };
        } catch (error) {
          console.error(`Failed to process row with ID ${row.id}:`, error);
          return { ...row, is_processing: false };
        }
      });

      const updatedRows = await Promise.all(updatePromises);

      setRows(
        rows.map((row) =>
          updatedRows.find((updatedRow) => updatedRow.id === row.id) || row
        )
      );
    } catch (error) {
      console.error("Error:", error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response?.data?.detail || "An unexpected error occurred");
      setSnackbarAlert("error");
    } finally {
      setIsProcessing(false);
    }
  };


  const handleRowUpdate = async (newRow, oldRow) => {
    const oldRowExtension = oldRow.document_name.split('.').pop();
    if (!newRow.document_name.endsWith(`.${oldRowExtension}`)) {
      newRow.document_name = `${newRow.document_name}.${oldRowExtension}`;
    }

    await updateDocument(newRow);
    if (newRow.is_editing && newRow.order_header_id) {
      setRows(
        rows.map((r) =>
          r.id === newRow.id ? { ...r, is_editing: false, order_header_id: newRow.order_header_id } : r
        )
      );
    } else if (newRow.is_editing && newRow.order_detail_id) {
      setRows(
        rows.map((r) =>
          r.id === newRow.id ? { ...r, is_editing: false, order_detail_id: newRow.order_detail_id } : r
        )
      );
    } else if (!newRow.order_detail_id && oldRow.order_detail_id) {
      setRows(
        rows.map((r) =>
          r.id === newRow.id ? { ...r, order_detail_id: null } : r
        )
      );
    } else if (!newRow.order_header_id && oldRow.order_header_id) {
      setRows(
        rows.map((r) =>
          r.id === newRow.id ? { ...r, order_header_id: null } : r
        )
      );
    }
    return newRow;
  };

  const handleLinkDocument = async (row) => {
    setRows(
      rows.map((r) =>
        r.id === row.id ? { ...r, is_editing: true } : { ...r, is_editing: false }
      )
    );
    let data;
    if (row.category !== "Certificate of Analysis") {
      data = await fetchOrdersV2(null, row.integration_id);
    } else {
      data = await fetchOrderDetailsV2(null, row.integration_id);
    }
    setLinkOptions(data);
  }


  const columns = [
    { field: 'source', headerName: 'Data Source', flex: 0.5 },
    {
      field: 'integration_name',
      headerName: 'Partner',
      flex: 0.5,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        return (
          <Select
            value={params.row.integration_id}
            size='small'
            onChange={(e) => handleDocumentChange(e, params.row, "integration_id")}
            variant='standard'
            fullWidth
            sx={{
              fontFamily: 'inherit',
              fontSize: 'inherit',
              color: integrations.find(integration => params.row.integration_id === integration.id)?.color_code ? integrations.find(integration => params.row.integration_id === integration.id)?.color_code : 'inherit'
            }}
          >
            {integrations.map(integration => (
              <MenuItem key={integration.id} value={integration.id}>
                {integration.name}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: 'product_category',
      headerName: 'Product Category',
      flex: 0.75,
      headerClassName: 'super-app-theme--header-new',
      editable: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.75,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        return (
          <Select
            value={params.row.category}
            size='small'
            onChange={(e) => handleDocumentChange(e, params.row, "category")}
            variant='standard'
            fullWidth
            sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
          >
            {docTypes.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        );
      }
    },
    {
      field: 'document_name',
      headerName: 'Document',
      flex: 1.5,
      headerClassName: 'super-app-theme--header-new',
      editable: true,
    },
    {
      field: 'msg_timestamp', headerName: 'Created At', width: 140,
      renderCell: (params) => {
        const value = params.value || params.row.create_time;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const icons = [];
        const documentName = params.row.document_name;
        const isViewable = /\.(pdf|png|jpeg)$/i.test(documentName);

        icons.push(
          <Tooltip title={isViewable ? "View Document" : "Download Document"} key={`${documentName}-${isViewable ? 'view' : 'download'}`}>
            <IconButton onClick={() => handleFetchDocument(params.row)}>
              {isViewable ? <SearchOutlinedIcon color='info' /> : <DownloadIcon sx={{ color: 'black' }} />}
            </IconButton>
          </Tooltip>
        );

        if (params.row.order_header_id || params.row.order_detail_id) {
          icons.push(
            <Tooltip key={`${params.row.id}-linked`} title="Document is linked. Click to unlink.">
              <IconButton onClick={() => {
                const updatedRow = { ...params.row, order_detail_id: null, order_header_id: null };
                handleRowUpdate(updatedRow, params.row);
              }}
              >
                <CheckCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          );
        }

        if (
          ['Sales Order', 'Purchase Order', 'Receiving Order'].includes(params.row.category) &&
          !params.row.order_header_id &&
          !params.row.document_name.endsWith('.zip') &&
          !params.row.document_name.endsWith('.xlsx')
        ) {
          icons.push(
            params.row.is_processing === true ? (
              <CircularProgress key={`genai-icon-${params.row.id}-loading`} size={16} />
            ) : (
              <IconButton
                key={`genai-icon-${params.row.id}`}
                onClick={async () => {
                  await handleAnalyzeDocument(params.row);
                }}
              >
                <AutoFixHighIcon sx={{ color: 'black' }} />
              </IconButton>
            )
          );
        } else if (!params.row.order_header_id && !params.row.order_detail_id) {
          icons.push(
            <Tooltip key={`${params.row.id}-linked`} title="Link Document">
              <IconButton onClick={() => handleLinkDocument(params.row)}>
                <LinkIcon color="inherit" />
              </IconButton>
            </Tooltip>
          );
        }

        icons.push(
          <Tooltip key={`${params.row.id}-pin`} title={params.row.is_pinned ? "Unpin Document" : "Pin Document"}>
            <IconButton
              onClick={async () => {
                try {
                  await togglePinDocumentV2(params.row.id);
                  setRows(rows.map((r) => (r.id === params.row.id ? { ...r, is_pinned: !r.is_pinned } : r)));
                } catch (error) {
                  console.error('Error:', error);
                }
              }}
            >
              {params.row.is_pinned ? <PushPinIcon sx={{ color: 'black' }} /> : <PushPinOutlinedIcon sx={{ color: 'black' }} />}
            </IconButton>
          </Tooltip>
        );

        if (params.row.source === 'Email') {
          icons.push(
            <IconButton
              key={`email-icon-${params.row.id}`}
              component="a"
              href={`https://mail.google.com/mail/u/0/#inbox/${params.row.msg_id}`}
              target="_blank"
            >
              <EmailOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        }

        icons.push(
          <IconButton key={`delete-icon-${params.row.id}`} onClick={() => handleDeleteDocument(params.row.id)}>
            <DeleteOutlineOutlinedIcon color='error' />
          </IconButton>
        );
        return (
          params.row.is_editing ? (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title="Cancel">
                <IconButton onClick={() => {
                  setRows(
                    rows.map((r) =>
                      r.id === params.row.id ? { ...r, is_editing: false } : r
                    )
                  );
                  setLinkOptions([]);
                }}>
                  <ClearIcon color='error' fontSize='small' />
                </IconButton>
              </Tooltip>
              <Select
                value={params.row.integration_id}
                size='small'
                variant='standard'
                fullWidth
                onChange={(e) => {
                  const linkToId = e.target.value;
                  let updatedRow;
                  if (params.row.category === "Certificate of Analysis") {
                    updatedRow = { ...params.row, order_detail_id: linkToId };
                  } else {
                    updatedRow = { ...params.row, order_header_id: linkToId };
                  }
                  handleRowUpdate(updatedRow, params.row);
                }}
                sx={{ fontFamily: 'inherit', fontSize: 'inherit', my: '0.5rem' }}
              >
                {linkOptions.map(linkOption => (
                  <MenuItem key={linkOption.id} value={linkOption.id}>
                    {params.row.category === "Certificate of Analysis" ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontWeight: 'bold' }}>{linkOption.order_name}</Box>
                        <Box sx={{ mx: 0.5 }}> - </Box>
                        <Box>{linkOption.item_name}</Box>
                      </Box>
                    ) : (
                      <Box sx={{ fontWeight: 'bold' }}>{linkOption.order_name}</Box>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 0 }}>
              {icons}
            </Box>
          )
        );
      },
      headerClassName: 'super-app-theme--header-new'
    }
  ];


  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <>
          <Box sx={{ display: 'flex', px: '0.25rem', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleUploadClick}
                sx={{
                  borderRadius: '10px',
                }}
              >
                Add Document(s)
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                onChange={handleFileChange}
              />
              <Button
                sx={{ borderRadius: '10px' }}
                color='inherit'
                startIcon={<ContentPasteIcon />}
                onClick={handlePasteClipboard}
              >
                Click to paste from clipboard
              </Button>
            </Box>
            {isProcessing ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: 'center', width: '10%', mt: '0.5rem' }}>
                <LinearProgress sx={{ width: '100%' }} />
              </Box>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                startIcon={<AutoFixHighIcon />}
                onClick={handleAnalyzeDocuments}
                sx={{
                  borderRadius: '10px',
                }}
              >
                Auto Parse
              </Button>
            )}
          </Box>
        </>
      </GridToolbarContainer >
    );
  };


  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          bgcolor: 'white',
          height: height,
          maxHeight: height,
          width: '100%',
          borderRadius: '5px',
        }}
      >
        {!blobUrl ? (
          <Box sx={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              density="compact"
              loading={loading}
              disableRowSelectionOnClick
              processRowUpdate={handleRowUpdate}
              hideFooter
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </Box>
        ) : (
          <>
            <Box sx={{ width: '50%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                density="compact"
                columnVisibilityModel={{
                  source: false,
                  edited_by: false,
                  last_updated: false,
                }}
                hideFooter
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
            {blobType === 'application/pdf' ? (
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                height="100%"
                maxHeight="70vh"
                overflow="auto"
              >
                <PDFViewer pdfBlob={blobUrl} setBlobType={setBlobType} setBlobUrl={setBlobUrl} />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                height="100%"
                maxHeight="70vh"
                overflow="auto"
              >
                <Box display={'flex'} flexDirection={'row'} m={'0.25rem'}>
                  <Button
                    variant="contained"
                    size='small'
                    onClick={() => {
                      setBlobType('');
                      setBlobUrl('');
                    }}
                  >
                    Close Document
                  </Button>
                </Box>
                <img src={blobUrl} alt="docImg" />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}